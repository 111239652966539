import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Multiselect,
  Input,
  Button,
  SpaceBetween,
  Form,
  Select,
  Header,
  Table,
  ExpandableSection
} from "@cloudscape-design/components";

import config from "../../config";
import useFetchData from '../../hooks/useFetchData';

const StackConfigDisplay = ({ token, customer, setRefreshKey, setFlashMessages, pendingMessage }) => {
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [saveButtonVariant, setSaveButtonVariant] = useState("primary");
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const { data: templatesData, loading, error } = useFetchData(token, 'templates', 'GET', setRefreshKey);
  const [state, setState] = useState({
    parameters: {},
    selectedTemplates: customer.stacks.map(stack => stack),
  });

  useEffect(() => {
    if (!loading && templatesData && customer) {
      const newParamMap = templatesData.templates.reduce((acc, template) => {
        // Find the matching stack if it exists
        const stack = customer.stacks.find(stack => stack.TemplateName === template.name);

        // Create parameter map by overriding template parameters with stack parameters if they exist and value is not blank
        const tmpmap = template.parameters.reduce((paramAcc, param) => {
          const stackValue = stack?.Parameters.find(p => p.ParameterKey === param.name);
          paramAcc[param.name] = stackValue && stackValue.ParameterValue ? { ...param, value: stackValue.ParameterValue } : param;
          return paramAcc;
        }, {});

        acc[template.name] = tmpmap;
        return acc;
      }, {});

      setState((prevState) => ({
        ...prevState,
        parameters: newParamMap,
      }));
    }
  }, [loading, templatesData, customer]);

  const enableSaveButton = () => {
    setSaveButtonLoading(false);
    setSaveButtonVariant("primary");
    setSaveButtonDisabled(false);
  };

  const resetSaveButton = () => {
    setSaveButtonLoading(false);
    setSaveButtonVariant("primary");
    setSaveButtonDisabled(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSaveButtonLoading(true);

    // Create a new version of selectedTemplates with parameters overridden from the state
    const updatedTemplates = state.selectedTemplates.map((template) => {
      const updatedParameters = Object.values(state.parameters[template.TemplateName] || {}).map((param) => {
        return {
          ...param,
          value: state.parameters[template.TemplateName][param.name]?.value || param.value,
        };
      });

      return {
        ...template,
        Parameters: updatedParameters,
      };
    });

    const postData = {
      customer: { ...customer },
      selectedTemplates: updatedTemplates,
    };

    try {
      const response = await fetch(`${config.api_endpoint}/customers/${customer.customer_code}`, {
        mode: "cors",
        method: "PUT",
        headers: {
          "content-type": "application/json",
          "x-authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(postData),
      });
      if (!response.ok) {
        throw new Error("Failed to update customer data");
      }
      await response.json();
      setRefreshKey(1);
      resetSaveButton();
      setFlashMessages([{
        header: "Pending changes to apply",
        type: "warning",
        content:
          "The stack configuration still has changes to apply to one or more stacks. When saving a customer, stacks do not automatically update. You must trigger the update from the Cloudformation Stacks tab.",
        dismissible: false,
        id: "stacker_pending",
      }]);
    } catch (error) {
      console.error(error);
      resetSaveButton();
    }
  };



  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }


  return (
      <SpaceBetween size="m">
        <form onSubmit={handleSubmit}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button loading={saveButtonLoading} variant={saveButtonVariant} disabled={saveButtonDisabled}>
                  Save Changes
                </Button>
              </SpaceBetween>
            }
          >
              <ExpandableSection header="Stacks Selection" defaultExpanded={true}>
                <Multiselect
                  selectedOptions={state.selectedTemplates.map((template) => ({
                    label: template.TemplateName,
                    value: template.TemplateName,
                    template: template
                  }))}
                  onChange={({ detail }) => {

                    const selectedTemplates = detail.selectedOptions.map((option) => ({
                      TemplateName: option.value,
                      Parameters: option.template.Parameters
                    }));

                    const updatedParameters = selectedTemplates.reduce((acc, template) => {
                      if (state.parameters[template.TemplateName]) {
                        // Preserve existing parameters if they already exist
                        acc[template.TemplateName] = state.parameters[template.TemplateName];
                      } else {
                        // Initialize parameters for newly selected templates
                        acc[template.TemplateName] = template.Parameters.reduce((paramAcc, param) => {
                          paramAcc[param.name] = param;
                          return paramAcc;
                        }, {});
                      }
                      return acc;
                    }, {});

                    setState((prevState) => ({
                      ...prevState,
                      selectedTemplates,
                      parameters: updatedParameters,
                    }));

                    enableSaveButton();
                  }}

                  options={templatesData['templates'].map((template) => ({
                    label: template.name,
                    value: template.name,
                    template: {
                      TemplateName: template.name,
                      Parameters: template.parameters
                    }
                  }))}
                  placeholder="Select stacks to configure"
                />
              </ExpandableSection>

            <hr />

            {state.selectedTemplates.map((template, templateKey) => {

              if (template.Parameters.length === 0) {
                return null
              }

              return (
                <ExpandableSection key={templateKey} header={"Parameters for " + template.TemplateName + " (" + template.Parameters.length + ")"} defaultExpanded={true}>

                  <Table
                    header={<Header variant="h3">Parameters for {template.TemplateName}</Header>}
                    columnDefinitions={[
                      {
                        id: "parameter",
                        header: "Parameter Name",
                        cell: (item) => item.name,
                        width: 300,
                      },
                      {
                        id: "value",
                        header: "Value",
                        cell: (item) => (
                          item.type === "string" ? (
                            <Input
                              value={item.value}
                              onChange={(e) => {
                                const newValue = e.detail.value;
                                setState((prev) => ({
                                  ...prev,
                                  parameters: {
                                    ...prev.parameters,
                                    [template.TemplateName]: {
                                      ...prev.parameters[template.TemplateName],
                                      [item.name]: {
                                        ...prev.parameters[template.TemplateName][item.name],
                                        value: newValue,
                                      },
                                    },
                                  },
                                }));
                                enableSaveButton();
                              }}
                              ariaLabel="Parameter input"
                            />
                          ) : (
                            <Select
                              expandToViewport={true}
                              selectedOption={{
                                label: item.value,
                                value: item.value,
                              }}
                              onChange={({ detail }) => {
                                const newValue = detail.selectedOption.value;
                                setState((prev) => ({
                                  ...prev,
                                  parameters: {
                                    ...prev.parameters,
                                    [template.TemplateName]: {
                                      ...prev.parameters[template.TemplateName],
                                      [item.name]: {
                                        ...prev.parameters[template.TemplateName][item.name],
                                        value: newValue,
                                      },
                                    },
                                  },
                                }));
                                enableSaveButton();
                              }}
                              options={item.valid_values.split(",").map((value) => ({
                                label: value,
                                value: value,
                              }))}
                              ariaLabel="Parameter select"
                              dropdownProps={{
                                dropdownPosition: "top",
                              }}
                            />
                          )
                        ),
                        width: 400,
                      },
                    ]}
                    items={Object.values(state.parameters[template.TemplateName] || {})}
                  />


                </ExpandableSection>)


            })
            }
          </Form>
        </form>
      </SpaceBetween>
  );
};

const mapStateToProps = (state) => ({
  token: state.token.accessToken,
});

export default connect(mapStateToProps)(StackConfigDisplay);
