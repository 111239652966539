import { useState } from "react";
import { connect } from "react-redux";

import {
  Button
} from '@cloudscape-design/components';

import ConfirmationPopup from "../common/ConfirmationPopup"
import InvalidApiRequestError from "../../errors/InvalidApiRequestError";
import { useLayout } from '../../layout/LayoutContext';

import config from "../../config";

const CloudformationStackAvailableActions = ({token, item, customer, setRefreshKey, selectedRegion}) => {

    const {
        setFlashMessages
    } = useLayout();

    const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);


    const handleDeployStack = (e) => {
        makeReq("deploy")
    }

     const handleUpdateStack = (e) => {
        makeReq("update")
    }



    const makeReq = (action) => {
        setConfirmModalVisible(false);
        fetch(`${config.api_endpoint}/customers/${customer.customer_code}/cloudformation/stacks/${encodeURIComponent(item['StackName'])}/${action}`, {
            mode: "cors",
            method: "PUT",
            headers: {
              "content-type": "application/json",
              "x-authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ stack: item, region: selectedRegion }),
        })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new InvalidApiRequestError(response);
            }
        })
        .then((json) => {
            setRefreshKey(1)
            setFlashMessages([
                {
                    header: "Stack created",
                    type: "success",
                    content: `Stack creation has started. StackId: ${json['StackId']}`,
                    dismissible: true,
                    dismissLabel: "Dismiss message",
                    onDismiss: () => setFlashMessages([]),
                    id: "deploy_stack_success",
                }
            ]);
            return true
        })
        .catch(error => error)
        .then((json) => {
            console.log(json)
            setFlashMessages([
                {
                    header: "Error creating stack",
                    type: "error",
                    content: json.message,
                    dismissible: true,
                    dismissLabel: "Dismiss message",
                    onDismiss: () => setFlashMessages([]),
                    id: "deploy_stack_error",
                }
            ]);
        })
    }

    const handleCancel = () => {
        setConfirmModalVisible(false);
    };


    let action = null
    const deployable_states = ["PENDING_CREATE","DELETE_COMPLETE"]

    const updateable_states = ["UPDATE_REQUIRED"]

    if (deployable_states.includes(item['StackStatus']) && item['TemplateName'] !== "") {
        action = {
            button: {
                loading: false,
                text: "Create",
                disabled: false,
            },
            confirm: {
                title: "Are you sure?",
                message: `Please confirm you want to create this stack in the region ${selectedRegion['value']}?`,
                onConfirm: handleDeployStack
            }
        }
    }

    if (updateable_states.includes(item['StackStatus']) && item['TemplateName'] !== "") {
        action = {
            button: {
                loading: false,
                text: "Update",
                disabled: false,
            },
            confirm: {
                title: "Are you sure?",
                message: `Please confirm you want to update this stack in the region ${selectedRegion['value']}?`,
                onConfirm: handleUpdateStack
            }
        }
    }

    if (!action) {
        return
    }

    return (
        <>
            <Button variant="inline-link"
                onClick={() => setConfirmModalVisible(true)}
                disabled={action.button.disabled}
                loading={action.button.loading}
            >
              {action.button.text}
            </Button>
            <ConfirmationPopup
              visible={isConfirmModalVisible}
              onConfirm={action.confirm.onConfirm}
              onCancel={handleCancel}
              title={action.confirm.title}
              message={action.confirm.message}
            />
        </>
    )

}

const mapStateToProps = (state) => ({
  token: state.token.accessToken,
});

export default connect(mapStateToProps)(CloudformationStackAvailableActions);
